import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import { MatTableDataSource } from '@angular/material/table';
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from '../../assets/table-data';
import { Observable, forkJoin, from, of } from 'rxjs';
import { Userinfo } from 'src/app/services/userinfo';
import { AngularFireAuth } from '@angular/fire/auth';
import { Location } from '@angular/common';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import { NotificationRepository } from 'src/app/repository/NotificationRepository';

type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css'],
})
export class DeviceListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.devicelist);

  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public devices: any[] = [];
  public users: Observable<any[]>;
  obsDevices: Observable<any[]>;

  onlyActiveDevice = true;

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  public isMaster = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private userInfo: Userinfo,
    private location: Location
  ) {
    setInterval(() => {
      if (this.location.path(true).indexOf('device-list') != -1) {
        this.sortTable(this.sortHeaderNum, 'desc');
      }
    }, 1000);

    this.afAuth.currentUser.then(async (user) => {
      const snapShot = await firebase.default
        .firestore()
        .collection('managers')
        .where('uid', '==', user.uid)
        .get();
      this.isMaster = snapShot.docs.length === 0;
    });

    this.userInfo.getStorage('showOnlyActiveDevice').then((ret) => {
      if (ret) {
        this.onlyActiveDevice = true;
      } else {
        this.onlyActiveDevice = false;
      }
    });

    const get1MinuteAgo = () => {
      const now = new Date();
      now.setSeconds(now.getSeconds() - 60);
      return now;
    };

    firebase.default
      .firestore()
      .collection('devices')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          // doc.data() is never undefined for query doc snapshots
          var data = doc.data();
          const oneMinutesAgo = get1MinuteAgo().getTime();
          const tentouTimes = data.alertsHistory
            ?.map((record) => {
              const cols = record.split('_');
              return cols[1] === '転倒モニタリング' ? new Date(cols[0]) : null;
            })
            .filter((x) => x);

          data['isTentou'] = tentouTimes?.some(
            (time) => time.getTime() > oneMinutesAgo
          );
          data['relatedData'] = this.db
            .collection<any>(data.macAdress, (ref) => {
              return ref.orderBy('timestamp', 'desc').limit(1);
            })
            .snapshotChanges()
            .pipe(
              map((actions) =>
                actions.map((action) => {
                  var data = action.payload.doc.data();
                  data['time'] = this.formatDate(
                    new Date(data.timestamp.seconds * 1000),
                    'yyyy.MM.dd:HH:mm:ss'
                  );
                  console.log(data);
                  return data;
                })
              )
            );

          try {
            const subcate = data?.wearerId
              ? await firebase.default
                  .firestore()
                  .collection('subcate')
                  .doc(data?.wearerId)
                  .get()
              : null;
            const subcateData = subcate?.data();

            if (subcateData) {
              data['workTime'] = {
                start: subcateData.workStartTime,
                end: subcateData.workEndTime,
              };
            }
          } catch (e) {
            console.error(e);
          }

          this.devices.push(data);
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    this.users = db
      .collection<any>('subcate')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((action) => {
            const data = action.payload.doc.data();

            if (data.phone == undefined) {
              data.phone = '';
            }
            return data;
          })
        )
      );
  }

  async sendNotification(device: any) {
    const { value } = await Swal.fire({
      title: this.lang.show('メッセージを選択'),
      input: 'select',
      inputOptions: {
        あいうえお: 'あいうえお',
        かきくけこ: 'かきくけこ',
        さしすせそ: 'さしすせそ',
        たちつてと: 'たちつてと',
        なにぬねの: 'なにぬねの',
        はひふへほ: 'はひふへほ',
      },
      inputPlaceholder: this.lang.show('メッセージを選択'),
      showCancelButton: true,
      inputValidator: (value) => {
        return !value ? this.lang.show('メッセージを選択してください') : null;
      },
    });

    if (value) {
      await NotificationRepository.SendNotification(device.macAdress, value);
      Swal.fire(`${this.lang.show('メッセージを送信しました')}: ${value}`);
    }
  }

  getIsLocationVisible(data: any): boolean {
    return DeviceRepository.getIsLocationVisible(data);
  }

  getIsWorkTime(device: any): boolean {
    if (!device.workTime) return true;
    const { start, end } = device.workTime;
    if (!start || !end) return true;

    const timeHead = '2000/1/1 ';
    const now = new Date();
    const nowTime = new Date(
      timeHead + now.getHours() + ':' + now.getMinutes()
    );
    const startTime = new Date(timeHead + device.workTime.start);
    const endTime = new Date(timeHead + device.workTime.end);

    return startTime.getTime() > endTime.getTime()
      ? startTime.getTime() <= nowTime.getTime() ||
          nowTime.getTime() <= endTime.getTime()
      : startTime.getTime() <= nowTime.getTime() &&
          nowTime.getTime() <= endTime.getTime();
  }

  clickedCheckBox() {
    this.userInfo.setStorage('showOnlyActiveDevice', this.onlyActiveDevice);
  }

  getUserById(id, users) {
    if (id == undefined || id === '') return this.lang.show('登録なし');
    if (users != null) {
      return users.find((item) => item.id === id)
        ? users.find((item) => item.id === id)
        : this.lang.show('登録なし');
    }
  }

  removeAlertHistory() {
    alert('fadfasdf');
  }
  showAlertHistory(dvc) {
    var htmls = '';

    const translateTextOfSeparetedWithColon = (text: string) =>
      text
        .split(':')
        .map((t) => (isNaN(Number(t)) ? this.lang.show(t) : t))
        .join(':');

    const histories = dvc.alertsHistory.sort((a, b) => {
      const aTime = new Date(a.split('_')[0]);
      const bTime = new Date(b.split('_')[0]);

      return aTime.getTime() > bTime.getTime() ? -1 : 1;
    });

    histories.forEach((hirouAlert) => {
      const info = hirouAlert.split('_');
      const time = info[0];
      const alert =
        translateTextOfSeparetedWithColon(info[1]) +
        translateTextOfSeparetedWithColon(info[2]);

      const date = Date.parse(time);
      const timeString = this.lang.showTime(new Date(date));

      htmls =
        htmls +
        '・' +
        this.lang.show('日時') +
        ': <b>' +
        timeString +
        '</b>, ' +
        '<a >' +
        alert +
        '</a>' +
        '<br>';
    });

    Swal.fire({
      title: `<strong>${this.lang.show('アラート履歴')}</strong>`,
      icon: 'warning',
      html: htmls,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '閉じる',
      // confirmButtonAriaLabel: 'Thumbs up, great!',
    });
  }

  sortTable(n, switchSort) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      switchcount = 0;
    table = document.getElementById('myTable2');
    switching = true;
    // Set the sorting direction to ascending:
    dir = 'desc';
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < rows.length - 1; i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (dir == 'asc') {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == 'desc') {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount == 0 && dir == 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    console.log(event.target.offsetHeight);
    console.log(event.target.scrollTop);
    console.log(event.target.scrollHeight);

    if (
      event.target.offsetHeight + event.target.scrollTop + 70 >=
      event.target.scrollHeight
    ) {
      console.log('End');
      this.numOfItemSlice = this.numOfItemSlice + 5;
    }
  }

  readMore() {
    this.numOfItemSlice = this.numOfItemSlice + 5;
  }

  getDateFormated(str) {
    if (str == null) return '';
    return str.split(':')[0];
  }

  getTimeFormated(str) {
    if (str == null) return '';
    return (
      str.split(':')[1] + ':' + str.split(':')[2] + ':' + str.split(':')[3]
    );
  }

  showAlert(str) {
    if (str.phone == undefined) {
      alert('電話番号が設定されていません');
    } else {
      alert('電話番号は「' + str.phone + '」です');
    }
  }

  formatDate(date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  }

  async selectUser(device, users, index) {
    console.log(device);
    var selections = {};
    users.forEach((user) => {
      selections[user.id] = user.name;
    });

    const { value: fruit } = await Swal.fire({
      title: '装着中のユーザを選択してください',
      input: 'select',
      inputOptions: selections,
      inputPlaceholder: '選択する',
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#3085d6',
      denyButtonText: `クリア`,
    });

    if (fruit) {
      firebase.default.firestore().collection('devices').doc(device.id).update({
        wearerId: fruit,
      });
      this.devices[index].wearerId = fruit;
    } else if (fruit == undefined) {
      console.log('canceled');
    } else if (fruit == false) {
      firebase.default.firestore().collection('devices').doc(device.id).update({
        wearerId: '',
      });
      this.devices[index].wearerId = '';
    }
  }

  sliceMacAdress(ma) {
    var ret = ma
      .replace(':', '')
      .replace(':', '')
      .replace(':', '')
      .replace(':', '')
      .replace(':', '')
      .replace(':', '')
      .replace(':', '');

    return ret.slice(-4);
  }

  checkOnGoingStateAndFilterOption(relatedData): boolean {
    if(!this.onlyActiveDevice)
    { return true; }

    const time = relatedData[0]?.timestamp?.toDate();
    if(!time || isNaN(time.getTime())) return false;

    const now = new Date();
    return now.getFullYear() === time.getFullYear() &&
      now.getMonth() === time.getMonth() &&
      now.getDate() === time.getDate();

    /*
    if (this.onlyActiveDevice) {
      if (relatedData == undefined) return false;
      if (relatedData[0] == undefined) return false;

      const itemDate = new Date(
        relatedData[0].timestamp.seconds * 1000
      ).getTime();
      const currentDate = new Date().getTime();

      var difference = currentDate - itemDate;
      if (difference > 100000) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }*/
  }

  showIfStrongNemuke(text: string | any | null | undefined): string {
    if (!text) return '';
    return text.indexOf('強い眠気') != -1 ? '強い眠気' : '';
  }

  showIfStrongHirou(text: string | any | null | undefined): string {
    if (!text) return '';
    return text.indexOf('強い疲労') != -1 ? '強い疲労' : '';
  }

  checkOnGoingState(relatedData) {
    if (relatedData == undefined) return false;
    if (relatedData[0] == undefined) return false;

    const itemDate = new Date(
      relatedData[0].timestamp.seconds * 1000
    ).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    // console.log(difference);
    if (difference > 300000) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const tableName = data.tableName as keyof typeof TABLES;
      const table = TABLES[tableName];

      this.dataSource = new MatTableDataSource<Element>(table.data);
      this.columns = table.columns;
      this.rowColumns = this.columns.map((c) => c.def);
    });
  }
}
